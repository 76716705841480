*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background:linear-gradient(31deg, #009BDF 0%,#46EBB4 100%);
}
.tab
{
  font-family: 'Lexend Deca', sans-serif;
  color:#5c5c5c;
}
body {
  overflow-y:scroll !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*------------------------
---------HEADER----------
-------------------------*/

.cambia{
  background-color:red;
}
.colorHeaderp
{
  color:#5c5c5c;
}
.Header
{

  background-image: url("assets/header.png");
  background-size:cover;
  background-image: url("assets/1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.Col_Header
{
  padding: 0 !important;
  margin: 0 !important;
}
.Col_Header_1
{
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: 0px 2px 16px #999;
  /*box-shadow: 0px 2px 16px #999;*/
  -webkit-box-shadow:  16px 0px 16px -16px #999,  -16px 0px 16px -16px #999;
  -moz-box-shadow:  16px 0px 16px -16px #999,  -16px 0px 16px -16px #999;
  box-shadow:  16px 0px 16px -16px #999,  -16px 0px 16px -16px #999;
}
.minHeight
{
  min-height: 70vh;
}
.seidecol{
  box-shadow: 0px 2px 16px #999;
}

.floatta
{
  display:inline-block;
  position:absolute;
  top:35%;
}


/*------------------------
---------MAIN PAGE--------
-------------------------*/

.info
{
  text-align: center;
  margin-top: 1%;
  border-bottom: 1px solid #cccccc;
}
.MainPage
{
  padding:0 !important;
}
.MainPageRow2
{
  background:white;
  padding:0 !important;
}
.shadow{box-shadow: 0px 2px 4px rgba(0,0,0,0.2);}


.sectionMain
{
  background: linear-gradient(31deg, #009BDF 0%,#00FFFF 100%);
  text-align: center;
  color:white;
  padding:16.5px;

  text-decoration:none;
  outline: 0 !important;
}
.Col_margin
{
  margin-left:0 !important;
  margin-right:0 !important;
  padding:0 !important;
}

.margin 
{
  margin-left: 12em;
}

.checktext
{

  font-family: 'Lexend Deca', sans-serif;
  text-decoration:none !important;
  color:#727272;
  font-size: 12px;
}
.mainback
{
  background: linear-gradient(31deg, #009BDF 0%,#00FFFF 100%);
}

/*------------------------
---------OVERVIEW--------
-------------------------*/

.OverviewEventi
{
  border: 3px solid #e6e6e6;
  border-radius: 18px 18px 18px 18px;
}
.OverviewEventi label{

  font-family: 'Lexend Deca', sans-serif;

  color:#5c5c5c;
  font-size: 12px;
}
.arrivo
{

  font-family: 'Lexend Deca', sans-serif;
  color:#5c5c5c;
  font-size: 10px;
}
.ora
{
  font-family: 'Lexend Deca', sans-serif;
  color:#5c5c5c;
  font-size: 10px;
}

/*------------------------
---------SIDEBAR----------
-------------------------*/

.divside{
  margin:0;
  padding:0;
  min-width: 170px;
  
}
.navside{
  width:100%;
  margin:0;
  padding:0;
  height:auto;
  font-family: 'Lexend Deca', sans-serif;
}
.lab_2
{
  cursor:pointer;
  font-size: 12px;
  font-weight: bold;
  padding-bottom:10px;
  font-family: 'Lexend Deca', sans-serif;
  display:block;
  text-decoration:none;
  text-align: left;
  color: #8b8b8b;
}
.margine_lab_2{
  margin-left: 10px;
}
.lab_2:hover{
  color: #009bdf;
}

.lab
{
  display:block;
  PADDING:10px;
  background-color: rgb(255, 255, 255);
  font-weight: 500;
  line-height: 1.1;
  font-size:30px;
  font-family: 'Lexend Deca', sans-serif;
  cursor:pointer;
  color:#808080;
  border-bottom: solid 2px #aaa;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.2);
  transition: 0.6s
}

.lab:hover{
  background-color:  rgba(238, 236, 236, 0.952);
  color: #009bdf;
}

.divside ul{
  margin:0px;
}

.divside ul{
  margin:0;
  padding:0;
  width:100%;
  list-style: none;
  overflow:hidden;
  max-height: 0;

}

.margineUlteriore{
  margin-left: 20px;
}
.divside ul li a{
  width:100%;
  height: 40px;
  font-size: 14px;
  transition: 0.8s;

}
.margineUlteriore{
  margin-left: 12px;
}
.margineUlterioreDue{
  margin-left: 22px;
}

.divside ul li a{
  width:100%;
  height: 40px;
  font-size: 12px;
  font-weight: bold;
  padding-bottom:10px;
  font-family: 'Lexend Deca', sans-serif;
  display:block;
  text-decoration:none;
  text-align: left;
  color: #8b8b8b;
  overflow:hidden;
  margin-left:10px;
  margin-left:4px;

}
.divside ul li a:hover{
  color: #009bdf;
  background-color: #fdfdfd;
}

.divside input:checked ~ label
{
  color: #009bdf;
}


.divside input:checked ~ ul{
  height: auto;
  max-height: 9600px;
  transform: all;
  transition: 6s;
}

.divside input:not(:checked) ~ ul{
  color: #009bdf;
  transform: all;
  transition: 0.2s;
}

.divside input{
  display:none;
}


/*------------------------
---------TODO PAGE--------
-------------------------*/
.myButton {
  background: linear-gradient(31deg, #009BDF 0%,#46EBB4 100%);
	border-radius:28px;
	display:inline-block;
  text-decoration: none;
  cursor:pointer;
	color:#ffffff;
	font-family: 'Lexend Deca', sans-serif;
	font-size:14px;
  font-weight: bold;
  outline: 0 !important;
  border: none;
	font-family:Arial;
	font-size:14px;
  padding:3px 74px;
  font-weight: bold;
  outline:none;
}
.myButton:hover {
  background-color:#22d1a5;
  text-decoration: none;
  color:#ffffff;
  font-weight: bold;
  border: none;
  outline: 0 !important;
}
.myButton:active {
	position:relative;
  top:1px;
  text-decoration: none;
  border: none;
  outline: 0 !important;
}
.myButton:active {
	position:relative;
	top:1px;
}

.bottom{
  padding:10px;
  border-bottom: 1px solid #cccccc;
}

/*------------------------
-----ISLANDER PAGE--------
-------------------------*/


.Islanders
{
  color:#009BDF;
}


/*------------------------
---------PROFILO---------
-------------------------*/


.profiloHeader
{
  background-image: url("./assets/isola.jpg");
  height: 10em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.nomeProfilo
{
  color:#009BDF;
  font-family: 'Lexend Deca', sans-serif;
  margin-top: 10%;
  text-align: center;
}

.circleBase {
  border: solid 1px rgb(45, 153, 241);
  position: absolute;
  top:15%;
  left:5%;
  border-radius: 50%;
  background-image: url("./assets/isola.jpg");
  background-size: cover;
  width: 120px;
  height:120px;
}

/*------------------------
-----MODIFICA PROFILO-----
-------------------------*/


.headerModificaProfilo
{
  margin-top: 2%;
  text-align: center;
  font-family: 'Lexend Deca', sans-serif;

  color: #8b8b8b;
}
.underModificaProfilo
{
  text-align: center;
  font-family: 'Lexend Deca', sans-serif;
 color: #8b8b8b;
  
}

.divrgb
{
  animation: color 10s ease infinite;
  border-radius: 8px 8px 0px 0px;

}

.divrgb:hover
{
  animation: color 2s ease infinite;
}
.hrgb
{
  text-align: center;
  font-family: 'Lexend Deca', sans-serif;
  padding:10px;
}

@keyframes color{
  0%
  {
    background: #00c5df;
  }
  20%
  {
    background: #00bedf;
  }
  40%
  {
    background: #00dfcd;
  }
  60%
  {
    background: #00df9c;
  }
  80%
  {
    background: #00df82;
  }
  100%
  {
    background: #00c5df;
  }
}

.Col_Header .none{
  text-decoration: none;
}


/*------------------------
------ISLANDERS--------
-------------------------*/


.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin: auto;
  text-align: center;
  font-family: 'Lexend Deca', sans-serif;

}


a {
  text-decoration: none;
  font-size: 22px;
  color: black;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/*------------------------
------ARCHIVEMENTS--------
-------------------------*/

.archivement
{
  background-image: linear-gradient(31deg, #009BDF 0%,#46EBB4 100%);
  text-align: center;
  color:#ffffff;
  font-weight: bold;

}

.card_2 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 500px;
  margin: auto;
  text-align: center;
  font-family: 'Lexend Deca', sans-serif;
}
.HSoldi
{
  background-color: #009BDF;
  align-items: center;
  text-align: center;
  margin: 0 !important;
  padding:0 !important;
}
.NomeArchivement
{
  background-color: #009BDF;
  align-items: center;
  text-align: center;
  margin: 0 !important;
  padding:0 !important;
}

.oro
{
  color:gold;
  font-size: 16px;

}
.margin{
  justify-items: auto;
  margin: 4px;
}

.margineProgress
{
  margin:3px;
}

/*------------------------
------MARKET PLACE--------
-------------------------*/

 
.market{
  max-width:800px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: auto;
  text-align: center;
  font-family: 'Lexend Deca', sans-serif;
}
.market img{
  margin-bottom:40px ;
  margin-top: 40px;
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.2);
  max-width:80%;
  max-height:80%;
}
.market a{
  text-decoration: none;
  color:#337ab7;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 500;
  line-height: 1.1;
}

.market a:hover{
  
  text-decoration: none;
  color:#3392b7;
  font-family: 'Lexend Deca', sans-serif;
}
.margine{
  margin-bottom: 20px;

}
.sectionMain a{
  color:white;
}
.sectionMain a:hover{
  color:rgb(59, 156, 201);
}


/*------------------------
-----ENCHANTED TABLES-----
-------------------------*/

.dataLink
{
  color:gray;
  font-size:13px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 500;
}
.dataLink:hover
{
  color:rgb(8, 80, 121);
  text-decoration: none;
}

/*------------------------
--------BREADCRUMBS-------
-------------------------*/


.bread
{
  color:white;
  font-size: 18px;
}


/*------------------------
--INTELLIGENCE OVERVIEW---
-------------------------*/

.intelligenceBar
{
  width:100%;
  margin:0;
  padding:0;
  height:auto;
  font-family: 'Lexend Deca', sans-serif;
}
.intelligenceDiv
{
  margin:0;
  padding:0;
  min-width: 170px;
    
}

.intelligenceLab
{
  display:block;
  PADDING:10px;
  background-color: white;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 500;
  line-height: 1.1;
  font-size:15px;
  font-family: inherit  ;
  cursor:pointer;
  color:#808080;
  border-bottom: solid 2px #aaa;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.2);
}

.intelligenceLab:hover{
  background-color:  rgba(238, 236, 236, 0.952);
  color: #009bdf;
}

.intelligenceDiv ul{
  margin:0px;
  padding:0;
  width:100%;
  list-style: none;
  overflow:hidden;
  max-height: 0;
  transition:all .4s linear ;

}
.intelligenceDiv ul li a{
  width:100%;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  padding-bottom:10px;
  font-family: 'Lexend Deca', sans-serif;
  display:block;
  text-decoration:none;
  text-align: left;
  color: #8b8b8b;
  overflow:hidden;
  margin-left:10px;

}
.intelligenceDiv ul li a:hover{
  color: #009bdf;
  background-color: #fdfdfd;
}

.intelligenceDiv input:checked ~ ul{
  height: auto;
  max-height: 960px;
  transform: all;
}

.intelligenceDiv input{
  display:none;
}

/*------------------------
-------COMMUNITY-------
-------------------------*/


@font-face {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/lexend-deca-v1-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Lexend Deca Regular'), local('LexendDeca-Regular'),
       url('./fonts/lexend-deca-v1-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/lexend-deca-v1-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/lexend-deca-v1-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/lexend-deca-v1-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/lexend-deca-v1-latin-regular.svg#LexendDeca') format('svg'); /* Legacy iOS */
}
.marketDiv
{
  border: solid 1px #aaa;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.2);
  display: inline-block;
  width: 100%;
  font-family: 'Lexend Deca', sans-serif;
  color: #8b8b8b;
  
}
.marketDiv a{
  text-decoration: none !important;
  color: #009bdf;
}
.immagine
{
  float:right;
  margin-right:20px;
  color: #8b8b8b;
}
.testi
{
  font-size:10px;
  margin-left:20px;
}
.titolo{
  margin-top:40px;
  margin-left:20px;
}
.descrizione
{
  margin-top:40px;
  margin-left:20px;
  margin-right:20px;
}

/*------------------------
-------SALES CRMMAP-------
-------------------------*/
.mappa
{
  box-shadow: 5px 2px 4px rgba(0,0,0,0.2);
}

/*------------------------
-----CALENDARIO INIZIO----
-------------------------*/


@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
.icon {
font-family: 'Material Icons', serif;
font-style: normal;
display: inline-block;
vertical-align: middle;
line-height: 1;
text-transform: none;
letter-spacing: normal;
word-wrap: normal;
white-space: nowrap;
direction: ltr;
-webkit-font-smoothing: antialiased;
text-rendering: optimizeLegibility;
-moz-osx-font-smoothing: grayscale;
font-feature-settings: 'liga';
}
/* GENERALE */
header {
display: block;
width: 100%;
padding: 1.75em 0;
border-bottom: 1px solid lightgray;
}
/* GRID */
.row {
margin: 0;
padding: 0;
display: flex;
flex-direction: row;
flex-wrap: wrap;
width: 100%;
}
.row-middle {
align-items: center;
}
.column {
flex-grow: 1;
flex-basis: 0;
max-width: 100%;
align-content: center;
}
.col-center{
  float:center;
}
.col-start{
  float:left;
  padding-left: 17%;
}
/* calendario */
.calendar {
display: block;
position: relative;
width: 100%;
background: white;
height: auto;
margin:0 auto;
}
.calendar .header {
align-content: center;
text-transform: uppercase;
font-weight: 700;
font-size: 115%;
padding: 1.5em 0;
border-bottom: 1px solid lightgray;
}
.calendar .header .icon {
cursor: pointer;
transition: .15s ease-out;
}
.calendar .header .icon:hover {
transform: scale(1.75);
transition: .25s ease-out;
color: dodgerblue;
}
.calendar .header .icon:first-of-type {
margin-left: 1em;
}
.calendar .header .icon:last-of-type {
margin-right: 1em;
}
.calendar .days {
text-transform: uppercase;
font-weight: 400;
color: gray;
font-size: 70%;
padding: .75em 0;
border-bottom: 1px solid lightgray;
}
.calendar .body .cell {
position: relative;
height: 6em;
border-right: 1px solid lightgray;
overflow: hidden;
cursor: pointer;
background: white;
transition: 0.25s ease-out;
}
.calendar .body .cell:hover {
background: whitesmoke;
transition: 0.5s ease-out;
}
.calendar .body .selected {
border-left: 10px solid transparent;
border-image: linear-gradient(31deg, #009BDF 0%,#46EBB4 100%);
border-image-slice: 1;
}
.calendar .body .row {
border-bottom: 1px solid lightgray;
}
.calendar .body .row:last-child {
border-bottom: none;
}
.calendar .body .cell:last-child {
border-right: none;
}
.calendar .body .cell .number {
position: absolute;
font-size: 82.5%;
line-height: 1;
top: .75em;
right: .75em;
font-weight: 700;
}
.calendar .body .cell:hover .number, .calendar .body .selected .number   {
visibility:hidden;
}
.calendar .body .disabled {
color: lightgray;
pointer-events: none;
}
.calendar .body .cell .bg {
font-weight: 700;
line-height: 1;
color: #1affa0;
opacity: 0;
font-size: 5em;
position: absolute;
top: -.2em;
right: -.05em;
transition: .25s ease-out;
letter-spacing: -.07em;
}
.calendar .body .cell:hover .bg, .calendar .body .selected .bg  {
opacity: 0.2;
transition: .5s ease-in;
}
.calendar .body .column {
flex-grow: 0;
flex-basis: calc(100%/7);
width: calc(100%/7);
}
/*------------------------
-----CALENDARIO FINE------
-------------------------*/



/*------------------------
-----GRAFICI------
-------------------------*/

.graficiDiv{
  display:inline-block;
  z-index:1;
}


/*------------------------
----HEADERPROFILEDUE------
-------------------------*/



.divsideDue{
  float: right;
  z-index:1;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.2);
  
}

.lab_2Due
{
  width:139px;
  padding:18px 42px;
  cursor:pointer;
  font-size: 12px;
  font-weight: bold;
  font-family: 'Lexend Deca', sans-serif;
  text-decoration:none;
  text-align: center;
  color: #8b8b8b;
  background-color: whitesmoke;
  list-style: none;
}
.lab_2Due:hover{
  color: #009bdf;
}

.divsideDue ul{
  position: absolute;
  margin:0px;
  padding:0;
  width:139px;
  list-style: none;
  overflow:hidden;
  max-height: 0;
  background-color: whitesmoke;
  z-index: 1;

}

.divsideDue ul li a{
  position: relative;
  background-color: whitesmoke;
  width:100%;
  height: 40px;
  font-size: 12px;
  font-weight: bold;
  font-family: 'Lexend Deca', sans-serif;
  display:block;
  text-decoration:none;
  text-align: center;
  color: #8b8b8b;
  overflow:hidden;
  z-index: 1;
}
.divsideDue ul li a:hover{
  color: #009bdf;
  background-color: #fdfdfd;
}

.divsideDue input:checked ~ ul{
  max-height: 1960px;
  z-index: 1;
  transition: 4s;
}

.divsideDue input{
  display:none;
  z-index: 1;
}
/*------------------------
-----AGGIUNGI CONTATTO-----
-------------------------*/

.ContenitoreContatto
{
  border: solid 1px rgb(0, 169, 221);
  padding:10px;
  margin-top: 20px;
  box-sizing: border-box;
}

/*------------------------
---------NUOVI FORM--------
-------------------------*/

.box input
{
  background:linear-gradient(31deg, #009BDF 0%,#46EBB4 100%);
  color:#fff;
  padding:10px;
  width:100%;
  font-family: 'Lexend Deca', sans-serif;
  border:none;
  height: 35px;
  font-size:12px;
  box-shadow: 0 2px 3px rgba(0,0,0,.5);
  outline:none !important;
}

.box select{
  height: 35px;
  background:linear-gradient(31deg, #009BDF 0%,#46EBB4 100%);
  color:#fff;
  padding:10px;
  width:100%;
  border:none;
  font-family: 'Lexend Deca', sans-serif;
  font-size:12px;
  box-shadow: 0 2px 3px rgba(0,0,0,.5);
  outline:none !important;
}

.box select option
{
  background-color:whitesmoke !important;
  color:#009BDF;
}

.box select:hover{
  box-shadow: 0 2px 6px rgba(34, 69, 80, 0.5);

}

.labelNuovo
{
  font-family: 'Lexend Deca', sans-serif;
  color:#5c5c5c;
  font-size:12px;
  font-weight: 400;
}

/*------------------------
----------PANEL-----------
-------------------------*/
.panelColor{
  background:rgb(204, 255, 145);
}
.panelColorRed{
  background:rgb(255, 149, 145);
}
.panel
{
  font-family: 'Lexend Deca', sans-serif;
  color:#cccccc;
  font-size:20px;
  /*animation: selezione 10s linear;*/
}

.panel:hover{
  background: rgb(49, 145, 209);
  color:#fff;
}

@keyframes selezione{
  0%
  {
    background: #00c5df;
  }
  20%
  {
    background: #00bedf;
  }
  40%
  {
    background: #00dfcd;
  }
  60%
  {
    background: #00df9c;
  }
  80%
  {
    background: #00df82;
  }
  100%
  {
    background: #00c5df;
  }
}





/*-------------------------
--------ISLAND PAGE--------  
--------------------------*/

.islandDiv
{
  border: solid 1px rgb(45, 153, 241);
  box-shadow: 2px 2px 4px rgba(0,0,0,0.2);
  display: inline-block;
  width: 100%;
  font-family: 'Lexend Deca', sans-serif;
  color: #8b8b8b;
  align-items: center;
  height:50vh;
  
}
.immagineHeader{
  background-image: url("./assets/1.jpg");
  background-repeat: no-repeat;
  height: 10em;
  background-size: cover;
  background-position: center;
}
.immagineIsola{
  border: solid 1px rgb(45, 153, 241);
  background-image: url("./assets/isola.jpg");
  background-repeat: no-repeat;
  height: 45%;
  width:40%;
  background-size: cover;
  background-position: center;
  position: absolute;
  left:30%;
  top:20%;
}
.islandDiv a:hover .immagineIsola{
  transition: 1s;
  border: solid 1px rgb(46, 48, 49);
}
.spanDiv
{
  width: 100%;
  text-align: center;
  position: absolute;
  top:65%;
  left:0;
  right:0;
}

/*-------------------------
--------AGGIUNGI ISOLA-----  
--------------------------*/

.bottoneIsola
{
  width: 100%;
  height: 100%;
  outline:none !important;
  border:none !important;
  padding:10px;
  font-size:15px;
  font-family: 'Lexend Deca', sans-serif;
  color: #8b8b8b;
}
.bottoneIsola:focus{
  transition: 1s;
  background:linear-gradient(31deg, #009BDF 0%,#46EBB4 100%);
  color:white;
}

/*-------------------------
---------FOOTER------------  
--------------------------*/

.footer
{
  text-align: center;
}
.footer a{
  font-size: 12px;
  color:#009BDF;
}
.hui
{
  font-weight: 600;
}


/*-------------------------
---------COMMENTI----------  
--------------------------*/

.commentiDiv{
  border: solid 1px rgb(226, 226, 226);
  padding: 0 !important;
}

.commentiSection{

  background: linear-gradient(31deg, #009BDF 0%,#00FFFF 100%);

  color:white;
  padding:16.5px;

  text-decoration:none;
  outline: 0 !important;
  font-family: 'Lexend Deca', sans-serif;
}


.bottoneCommentiInvia
{
  width: 100%;
  outline:none !important;
  border:none !important;
  padding:10px;
  font-size:15px;
  font-family: 'Lexend Deca', sans-serif;
  color: white;
  background:linear-gradient(31deg, #009BDF 0%,#46EBB4 100%);
}
.bottoneCommentiAnnulla
{
  width: 100%;
  outline:none !important;
  border:none !important;
  padding:10px;
  font-size:15px;
  font-family: 'Lexend Deca', sans-serif;
  color: white;
  background:linear-gradient(31deg, #6d0653 0%,#d846eb 100%);
  filter: brightness(1.00);

}
.bottoneCommentiAnnula:hover{
  filter: brightness(0.5);
}

.bottoneCommentiAllega
{
  width:100%;
  outline:none !important;
  border:none !important;
  padding:10px;
  font-size:15px;
  font-family: 'Lexend Deca', sans-serif;
  color: white;
  background:linear-gradient(31deg, #009BDF 0%,rgb(71, 214, 224) 100%);
}


.bottoniCommenti{
  width: 100%;
}
.noPad{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.noMar{
  padding: 0 !important;
  margin: 0 !important;
}

.textCommenti{
  font-family: 'Lexend Deca', sans-serif;
  color:#5c5c5c;
}


/*-------------------------
---------MARGINIFIX----------  
--------------------------*/

